<template>
  <div class="row content-wrapper">
    <LoadingSpinner v-if="isLoading" />
    <div class="col-md-12" v-else>
      <div class="row">
        <div class="col-md-12">
          <h2>{{ noticeArticle.title }}</h2>
          <article v-html="noticeArticle.description"></article>
        </div>
        <hr />
      </div>
      <div class="row">
        <div class="col-md-12">
          <router-link to="/notice" class="btn btn-primary">목록</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import LoadingSpinner from '@/components/common/LoadingSpinner.vue';

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState(['noticeArticle']),
  },
  components: {
    LoadingSpinner,
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions(['FETCH_NOTICE']),
    fetchData() {
      this.isLoading = true;
      this.FETCH_NOTICE(this.$route.params.id);
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
