<template>
  <div>
    <NoticeDetailComponent />
  </div>
</template>

<script>
import NoticeDetailComponent from '@/components/common/NoticeDetailComponent.vue';

export default {
  components: {
    NoticeDetailComponent,
  },
};
</script>

<style></style>
